.separador-perfil {
  color: blue;
}

.progress {
  border-top: 0;
  margin-right: -25px;
  margin-left: -25px;
  margin-top: -30px;
}

.card-header {
  color: #0c5460 !important;
  background-color: #d1ecf1 !important;
  border-color: #bee5eb !important;
}

.card-header h2 {
  margin-top: 0px !important;
  color: #0c5460 !important;
  text-decoration: none;
}

.card-header .btn-link {
  font-weight: 400;
  color: #0c5460 !important;
}

.card-header .btn-link:hover,
.card-header .btn-link:focus {
  text-decoration: none;
}

.preference-checkbox label {
  margin-bottom: 0px;
}

.smallPreference {
  margin-top: 0px;
  margin-bottom: 0.8rem;
}
