a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

#clientes .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}

#clientes
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content {
  font-size: 20px;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 20px;
  margin-top: -12px;
  text-align: center;
  color: white;
}

#clientes
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content
  i {
  margin-top: -12px;
}

#clientes
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content
  h3,
#clientes
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content
  h4 {
  margin: 0;
}

#clientes .portfolio-item .portfolio-caption {
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  text-align: center;
  background-color: #fff;
}

#clientes .portfolio-item .portfolio-caption h4 {
  margin: 0;
  text-transform: none;
}

#clientes .portfolio-item .portfolio-caption p {
  font-size: 16px;
  font-style: italic;
  margin: 0;
  font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.letras {
  font-size: 8px;
  margin: 0;
}

#clientes * {
  z-index: 2;
}

@media (min-width: 767px) {
  #clientes .portfolio-item {
    margin: 0 0 30px;
  }

  .search-date {
    min-width: 50px;
    margin-right: 15px;
  }
}

.portfolio-modal {
  padding-right: 0px !important;
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding: 100px 0;
  text-align: center;
}

.portfolio-modal .modal-content h2 {
  font-size: 3em;
  margin-bottom: 15px;
}

.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
  font-size: 16px;
  font-style: italic;
  margin: 20px 0 30px;
  font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-content ul.list-inline {
  margin-top: 0;
  margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content button {
  cursor: pointer;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.portfolio-modal .close-modal .lr {
  /* Safari and Chrome */
  z-index: 1051;
  width: 1px;
  height: 75px;
  margin-left: 35px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #212529;
}

.portfolio-modal .close-modal .lr .rl {
  /* Safari and Chrome */
  z-index: 1052;
  width: 1px;
  height: 75px;
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #212529;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after,
.timeline > li:before {
  display: table;
  content: " ";
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #fed136;
}

.timeline > li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }

  .search-date {
    min-width: 380px;
    margin-right: 15px;
  }

  .dropdown-menu-left {
    left: -9rem !important;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 50px;
  text-align: center;
}

.team-member img {
  width: 225px;
  height: 225px;
  border: 7px solid #fff;
}

.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.team-member p {
  margin-top: 0;
}

section#contacto {
  background-color: #212529;
  background-image: url("./img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}

section#contacto .section-heading {
  color: #fff;
}

section#contacto .form-group {
  margin-bottom: 25px;
}

section#contacto .form-group input,
section#contacto .form-group textarea {
  padding: 20px;
}

section#contacto .form-group input.form-control {
  height: auto;
}

section#contacto .form-group textarea.form-control {
  height: 248px;
}

section#contacto .form-control:focus {
  border-color: #fed136;
  -webkit-box-shadow: none;
  box-shadow: none;
}

section#contacto ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

section#contacto :-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

section#contacto ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

section#contacto :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 50px;
  display: block;
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: white;
  border-radius: 100%;
  outline: none;
  background-color: #212529;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #00bfd8;
}

.registro {
  color: #fec503 !important;
}

.registro:hover {
  color: #fec503;
}

.error {
  color: #ff0000;
  background-color: rgba(255, 255, 255, 0.61);
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 70px 20px 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}
.white-bg {
  background-color: #ffffff;
}

.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
  color: inherit;
}
.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
  margin-right: -25px;
  margin-left: -25px;
  margin-top: 58px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.1);
}
.panel-heading h1,
.panel-heading h2 {
  margin-bottom: 5px;
}
.panel-body {
  padding: 15px;
}
/* Bootstrap 3.3.x panels */
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
}

.panel-footer {
  padding: 10px 15px;
  border-top: 1px solid #e7eaec;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #e7eaec;
}
.panel-default {
  border-color: #e7eaec;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.wrapper h2 {
  font-size: 24px;
}

.wrapper .h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 100;
}

.wrapper .breadcrumb {
  background-color: #ffffff;
  padding: 0;
  margin-bottom: 0;
}
.wrapper .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
}

.wrapper .breadcrumb > li a {
  color: inherit;
}

a:hover,
a:focus {
  text-decoration: none;
}

/*******************
Thumb size
*******************/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.img-circle:hover {
  cursor: pointer;
}

.radius {
  border-radius: 4px;
}

/*******************
Text Colors
*******************/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #fc4b6c !important;
}

.text-muted {
  color: #99abb4 !important;
}

.text-warning {
  color: #ffb22b !important;
}

.text-success {
  color: #26c6da !important;
}

.text-info {
  color: #1e88e5 !important;
}

.text-inverse {
  color: #2f3d4a !important;
}

html body .text-blue {
  color: #02bec9;
}

html body .text-purple {
  color: #7460ee;
}

html body .text-primary {
  color: #7460ee;
}

html body .text-megna {
  color: #00897b;
}

html body .text-dark {
  color: #99abb4;
}

html body .text-themecolor {
  color: #009efb;
}

/*******************
Background Colors
*******************/
.bg-primary {
  background-color: #7460ee !important;
}

.bg-success {
  background-color: #26c6da !important;
}

.bg-info {
  background-color: #1e88e5 !important;
}

.bg-warning {
  background-color: #ffb22b !important;
}

.bg-danger {
  background-color: #fc4b6c !important;
}

html body .bg-megna {
  background-color: #00897b;
}

html body .bg-theme {
  background-color: #009efb;
}

html body .bg-inverse {
  background-color: #2f3d4a;
}

html body .bg-purple {
  background-color: #7460ee;
}

html body .bg-light-primary {
  background-color: #f1effd;
}

html body .bg-light-success {
  background-color: #e8fdeb;
}

html body .bg-light-info {
  background-color: #cfecfe;
}

html body .bg-light-extra {
  background-color: #ebf3f5;
}

html body .bg-light-warning {
  background-color: #fff8ec;
}

html body .bg-light-danger {
  background-color: #f9e7eb;
}

html body .bg-light-inverse {
  background-color: #f6f6f6;
}

html body .bg-light {
  background-color: #f2f4f8;
}

html body .bg-white {
  background-color: #ffffff;
}

/*******************
Rounds
*******************/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 45px;
  height: 45px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #1e88e5;
}
.round img {
  border-radius: 100%;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info {
  background: #1e88e5;
}

.round.round-warning {
  background: #ffb22b;
}

.round.round-danger {
  background: #fc4b6c;
}

.round.round-success {
  background: #26c6da;
}

.round.round-primary {
  background: #7460ee;
}

.sparkchart {
  margin-bottom: -2px;
}

/*============================================================== 
File upload
 ============================================================== */
.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
  border-radius: 0px;
}

.fileinput .input-group-addon {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background: #ffffff;
  margin-bottom: 1px;
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

/*============================================================== 
widget-chart-page product review
 ============================================================== */
.product-review {
  margin: 0px;
  padding: 25px;
}
.product-review li {
  display: block;
  padding: 20px 0;
  list-style: none;
}
.product-review li .font,
.product-review li span {
  display: inline-block;
  margin-left: 10px;
}

.social-profile {
  text-align: center;
  background: rgba(7, 10, 43, 0.8);
}

.profile-tab li a.nav-link,
.customtab li a.nav-link {
  border: 0px;
  padding: 15px 20px;
  color: #99abb4;
}
.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #009efb;
  color: #009efb;
}
.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #009efb;
}

/*============================================================== 
Form addons page
============================================================== */
/*Bootstrap select*/
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.select2-container--default .select2-selection--single {
  border-color: #d9d9d9;
  height: 38px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 38px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 33px;
}

.input-form .btn {
  padding: 10px 12px;
}

/*============================================================== 
Form Material page
 ============================================================== */
/*Material inputs*/
.form-material .form-group {
  overflow: hidden;
}

.form-material .form-control {
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  -webkit-transition: background 0s ease-out 0s;
  -o-transition: background 0s ease-out 0s;
  transition: background 0s ease-out 0s;
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#009efb),
      to(#009efb)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
  background-image: -webkit-linear-gradient(#009efb, #009efb),
    -webkit-linear-gradient(#d9d9d9, #d9d9d9);
  background-image: -o-linear-gradient(#009efb, #009efb),
    -o-linear-gradient(#d9d9d9, #d9d9d9);
  background-image: linear-gradient(#009efb, #009efb),
    linear-gradient(#d9d9d9, #d9d9d9);
  border: 0 none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: none;
}

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.form-control-line .form-group {
  overflow: hidden;
}

.form-control-line .form-control {
  border: 0px;
  border-radius: 0px;
  padding-left: 0px;
  border-bottom: 1px solid #d9d9d9;
}
.form-control-line .form-control:focus {
  border-bottom: 1px solid #009efb;
}

/*******************
Pagination
******************/
.pagination-circle li.active a {
  background: #26c6da;
}

.pagination-circle li a {
  width: 40px;
  height: 40px;
  background: #f2f4f8;
  border: 0px;
  text-align: center;
  border-radius: 100%;
}
.pagination-circle li a:first-child,
.pagination-circle li a:last-child {
  border-radius: 100%;
}
.pagination-circle li a:hover {
  background: #26c6da;
  color: #ffffff;
}

.pagination-circle li.disabled a {
  background: #f2f4f8;
  color: rgba(120, 130, 140, 0.13);
}

/*******************
Form Dropzone
******************/
.dropzone {
  border: 1px dashed #d9d9d9;
}
.dropzone .dz-message {
  padding: 5% 0;
  margin: 0px;
}

/*******************
Form Pickers
******************/
.asColorPicker-dropdown {
  max-width: 260px;
}

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: -35px;
  height: 38px;
  width: 37px;
  border: 0;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-decoration: none;
}

table th {
  font-weight: 400;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #009efb;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background: #009efb;
  color: #ffffff;
}

.datepicker td,
.datepicker th {
  padding: 5px 10px;
}

/*******************
Form icheck
******************/
.icolors,
.icheck-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.icolors > li {
  padding: 0;
  margin: 2px;
  display: inline-block;
  height: 30px;
  width: 30px;
  background: #263238;
  text-align: center;
}

.icolors > li.active:after {
  content: "\2713 ";
  color: #ffffff;
  line-height: 30px;
}

.icolors > li:first-child {
  margin-left: 0;
}

.icolors > li.orange {
  background: #fc4b6c;
}

.icolors > li.yellow {
  background: #ffb22b;
}

.icolors > li.info {
  background: #1e88e5;
}

.icolors > li.green {
  background: #26c6da;
}

.icolors > li.red {
  background: #fb3a3a;
}

.icolors > li.purple {
  background: #7460ee;
}

.icolors > li.blue {
  background: #02bec9;
}

.icheck-list {
  float: left;
  padding-right: 50px;
  padding-top: 10px;
}

.icheck-list li {
  padding-bottom: 5px;
}

.icheck-list li label {
  padding-left: 10px;
}

/*******************
Form summernote
******************/
.note-popover,
.note-icon-caret {
  display: none;
}

.note-editor.note-frame {
  border: 1px solid #d9d9d9;
}
.note-editor.note-frame .panel-heading {
  padding: 6px 10px 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.label {
  display: inline-block;
}

/*******************
Table-Layout
******************/
.table thead th,
.table th {
  border: 0px;
}

.color-table.primary-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #f2f4f8;
}

.color-table.success-table thead th {
  background-color: #26c6da;
  color: #ffffff;
}

.color-table.info-table thead th {
  background-color: #1e88e5;
  color: #ffffff;
}

.color-table.warning-table thead th {
  background-color: #ffb22b;
  color: #ffffff;
}

.color-table.danger-table thead th {
  background-color: #fc4b6c;
  color: #ffffff;
}

.color-table.inverse-table thead th {
  background-color: #2f3d4a;
  color: #ffffff;
}

.color-table.dark-table thead th {
  background-color: #263238;
  color: #ffffff;
}

.color-table.red-table thead th {
  background-color: #fb3a3a;
  color: #ffffff;
}

.color-table.purple-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-table.muted-table thead th {
  background-color: #99abb4;
  color: #ffffff;
}

.color-bordered-table.primary-bordered-table {
  border: 2px solid #7460ee;
}
.color-bordered-table.primary-bordered-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-bordered-table.success-bordered-table {
  border: 2px solid #26c6da;
}
.color-bordered-table.success-bordered-table thead th {
  background-color: #26c6da;
  color: #ffffff;
}

.color-bordered-table.info-bordered-table {
  border: 2px solid #1e88e5;
}
.color-bordered-table.info-bordered-table thead th {
  background-color: #1e88e5;
  color: #ffffff;
}

.color-bordered-table.warning-bordered-table {
  border: 2px solid #ffb22b;
}
.color-bordered-table.warning-bordered-table thead th {
  background-color: #ffb22b;
  color: #ffffff;
}

.color-bordered-table.danger-bordered-table {
  border: 2px solid #fc4b6c;
}
.color-bordered-table.danger-bordered-table thead th {
  background-color: #fc4b6c;
  color: #ffffff;
}

.color-bordered-table.inverse-bordered-table {
  border: 2px solid #2f3d4a;
}
.color-bordered-table.inverse-bordered-table thead th {
  background-color: #2f3d4a;
  color: #ffffff;
}

.color-bordered-table.dark-bordered-table {
  border: 2px solid #263238;
}
.color-bordered-table.dark-bordered-table thead th {
  background-color: #263238;
  color: #ffffff;
}

.color-bordered-table.red-bordered-table {
  border: 2px solid #fb3a3a;
}
.color-bordered-table.red-bordered-table thead th {
  background-color: #fb3a3a;
  color: #ffffff;
}

.color-bordered-table.purple-bordered-table {
  border: 2px solid #7460ee;
}
.color-bordered-table.purple-bordered-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-bordered-table.muted-bordered-table {
  border: 2px solid #99abb4;
}
.color-bordered-table.muted-bordered-table thead th {
  background-color: #99abb4;
  color: #ffffff;
}

.full-color-table.full-primary-table {
  background-color: #f1effd;
}
.full-color-table.full-primary-table thead th {
  background-color: #7460ee;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-primary-table tbody td {
  border: 0;
}
.full-color-table.full-primary-table tr:hover {
  background-color: #7460ee;
  color: #ffffff;
}

.full-color-table.full-success-table {
  background-color: #e8fdeb;
}
.full-color-table.full-success-table thead th {
  background-color: #26c6da;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-success-table tbody td {
  border: 0;
}
.full-color-table.full-success-table tr:hover {
  background-color: #26c6da;
  color: #ffffff;
}

.full-color-table.full-info-table {
  background-color: #cfecfe;
}
.full-color-table.full-info-table thead th {
  background-color: #1e88e5;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-info-table tbody td {
  border: 0;
}
.full-color-table.full-info-table tr:hover {
  background-color: #1e88e5;
  color: #ffffff;
}

.full-color-table.full-warning-table {
  background-color: #fff8ec;
}
.full-color-table.full-warning-table thead th {
  background-color: #ffb22b;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-warning-table tbody td {
  border: 0;
}
.full-color-table.full-warning-table tr:hover {
  background-color: #ffb22b;
  color: #ffffff;
}

.full-color-table.full-danger-table {
  background-color: #f9e7eb;
}
.full-color-table.full-danger-table thead th {
  background-color: #fc4b6c;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-danger-table tbody td {
  border: 0;
}
.full-color-table.full-danger-table tr:hover {
  background-color: #fc4b6c;
  color: #ffffff;
}

.full-color-table.full-inverse-table {
  background-color: #f6f6f6;
}
.full-color-table.full-inverse-table thead th {
  background-color: #2f3d4a;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-inverse-table tbody td {
  border: 0;
}
.full-color-table.full-inverse-table tr:hover {
  background-color: #2f3d4a;
  color: #ffffff;
}

.full-color-table.full-dark-table {
  background-color: rgba(43, 43, 43, 0.8);
}
.full-color-table.full-dark-table thead th {
  background-color: #263238;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-dark-table tbody td {
  border: 0;
  color: #ffffff;
}
.full-color-table.full-dark-table tr:hover {
  background-color: #263238;
  color: #ffffff;
}

.full-color-table.full-red-table {
  background-color: #f9e7eb;
}
.full-color-table.full-red-table thead th {
  background-color: #fb3a3a;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-red-table tbody td {
  border: 0;
}
.full-color-table.full-red-table tr:hover {
  background-color: #fb3a3a;
  color: #ffffff;
}

.full-color-table.full-purple-table {
  background-color: #f1effd;
}
.full-color-table.full-purple-table thead th {
  background-color: #7460ee;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-purple-table tbody td {
  border: 0;
}
.full-color-table.full-purple-table tr:hover {
  background-color: #7460ee;
  color: #ffffff;
}

.full-color-table.full-muted-table {
  background-color: rgba(152, 166, 173, 0.2);
}
.full-color-table.full-muted-table thead th {
  background-color: #99abb4;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-muted-table tbody td {
  border: 0;
}
.full-color-table.full-muted-table tr:hover {
  background-color: #99abb4;
  color: #ffffff;
}
html body .font-medium {
  font-weight: 500;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}
.btn-secondary,
.btn-secondary.disabled {
  background: #ffffff;
  border: 1px solid #727b84;
  color: #727b84;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button,
input {
  overflow: visible;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .db {
  display: block;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}
html body .m-t-30 {
  margin-top: 30px;
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  height: auto;
  height: "6px";
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.profiletimeline .sl-left {
  float: left;
  margin-left: -60px;
  z-index: 1;
  margin-right: 15px;
}

.profiletimeline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px;
}

.profiletimeline {
  position: relative;
  margin-left: 70px;
  margin-right: 10px;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.tab-content > .active {
  display: block;
}
.profiletimeline .sl-date {
  font-size: 12px;
  color: #99abb4;
}

.profiletimeline a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

html body .m-r-10 {
  margin-right: 10px;
}

a.link {
  color: #455a64;
}

.profiletimeline .sl-left img {
  max-width: 40px;
}
.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

img {
  vertical-align: middle;
  border-style: none;
}

html body blockquote {
  border-left: 5px solid #009efb;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
}
html body .m-t-10 {
  margin-top: 10px;
}

.telefono {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.espacio {
  margin-right: 15px;
}

.modal-scroll {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.is-open-modal {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  opacity: 0.5;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:focus,
.list-group-item:hover {
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085;
}
a.list-group-item-primary:focus,
a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff;
}
a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e;
}
a.list-group-item-secondary:focus,
a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6;
}
a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #155724;
}
a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #155724;
  background-color: #b1dfbb;
}
a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460;
}
a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #0c5460;
  background-color: #abdde5;
}
a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404;
}
a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #856404;
  background-color: #ffe8a1;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24;
}
a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #818182;
}
a.list-group-item-light:focus,
a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #818182;
  background-color: #ececf6;
}
a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21;
}
a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.list-group-divider .list-group-item-figure {
  padding: 0.75rem 1rem;
}

.user-avatar-md {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 2.25rem;
}

.user-avatar {
  position: relative;
  margin-bottom: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  vertical-align: middle;
  border-radius: 4rem;
}

.list-group-item-figure {
  display: flex;
  align-items: center;
  color: #a6abbd;
}

.btn-navbar {
  background: none;
  border: none;
  color: black;
  box-shadow: none;
}

.btn-navbar:hover {
  background: none;
  border: none;
  color: #000;
}

.btn-navbar:focus {
  background: none;
  border: none;
  color: #000;
}

.btn-navbar:not(:disabled):not(.disabled).active,
.btn-navbar:not(:disabled):not(.disabled):active,
.show > .btn-navbar.dropdown-toggle {
  background: none;
  border: none;
  box-shadow: none;
  color: #000;
}

.btn-navbar:not(:disabled):not(.disabled).active:focus,
.btn-navbar:not(:disabled):not(.disabled):active:focus,
.show > .btn-navbar.dropdown-toggle:focus {
  box-shadow: none;
  color: #000;
}

.btn-navbar.focus,
.btn-navbar:focus {
  box-shadow: none;
  color: #000;
}

.ocultar {
  display: none;
}

.has-error .form-control {
  border-color: #a94442;
  border-radius: 4px !important;
}
.has-success .form-control:focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
select + .form-control-feedback {
  right: 30px !important;
  margin-top: 12px;
}
select + .form-control-feedback.fa.fa-check {
  color: #46ab6f !important;
}
select + .form-control-feedback.fa.fa-times {
  color: #a94442 !important;
}

.inputDatePlaceholder label {
  top: -1px;
  font-size: 0.8125em;
  color: #707c80;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  color: #707c80;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  margin: 3px 0;
  left: 5px;
}

.inputDatePlaceholder input[type="text"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  display: block;
  font-size: 0.85rem;
  height: 45px;
  padding: 15px 10px 0 5px;
  width: 100% !important;
  border: 1px solid #cfd6da;
}

.inputDatePlaceholder .react-datepicker-wrapper {
  width: 100% !important;
}

.inputPlaceholder,
.inputDatePlaceholder {
  position: relative;
}
.inputPlaceholder i {
  top: 20%;
}
.inputPlaceholder i.fa-check {
  color: #46ab6f;
}
.inputPlaceholder i.fa-times {
  color: #a94442;
}
.inputPlaceholder i.fa-spinner {
  color: #016db6;
}
.inputPlaceholder input[type="number"],
.inputPlaceholder input[type="password"],
.inputPlaceholder input[type="tel"],
.inputPlaceholder input[type="email"],
.inputPlaceholder input[value=""],
.inputDatePlaceholder input[type="text"] {
  border-color: #cfd6da;
}
.inputPlaceholder input[type="number"],
.inputPlaceholder input[type="password"],
.inputPlaceholder input[type="tel"],
.inputPlaceholder input[type="email"],
.inputPlaceholder input[type="text"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  display: block;
  font-size: 1rem;
  height: 45px;
  padding: 15px 10px 0 5px;
  width: 100%;
}
.inputPlaceholder input[type="number"]:focus,
.inputPlaceholder input[type="password"]:focus,
.inputPlaceholder input[type="tel"]:focus,
.inputPlaceholder input[type="email"]:focus,
.inputPlaceholder input[type="text"]:focus,
.inputDatePlaceholder input[type="text"]:focus {
  border-color: #66afe9 !important;
  outline: 0;
}
active state .inputPlaceholder input[type="number"]:focus ~ .highlight,
active state .inputPlaceholder input[type="password"]:focus ~ .highlight,
active state .inputPlaceholder input[type="tel"]:focus ~ .highlight,
active state .inputPlaceholder input[type="email"]:focus ~ .highlight,
active state .inputPlaceholder input[type="text"]:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.1s ease-in;
  -moz-animation: inputHighlighter 0.1s ease-in;
  animation: inputHighlighter 0.1s ease-in;
}
.inputPlaceholder input[type="number"]:focus:invalid,
.inputPlaceholder input[type="password"]:focus:invalid,
.inputPlaceholder input[type="tel"]:focus:invalid,
.inputPlaceholder input[type="email"]:focus:invalid,
.inputPlaceholder input[type="text"]:focus:invalid {
  border: inherit;
}
.inputPlaceholder input[type="number"]:invalid,
.inputPlaceholder input[type="number"]:required,
.inputPlaceholder input[type="password"]:invalid,
.inputPlaceholder input[type="password"]:required,
.inputPlaceholder input[type="tel"]:invalid,
.inputPlaceholder input[type="tel"]:required,
.inputPlaceholder input[type="email"]:invalid,
.inputPlaceholder input[type="email"]:required,
.inputPlaceholder input[type="text"]:invalid,
.inputPlaceholder input[type="text"]:required {
  border: 1px solid #cfd6da;
  margin-bottom: 0;
}
.inputPlaceholder input[type="number"]:read-only ~ label,
.inputPlaceholder input[type="password"]:read-only ~ label,
.inputPlaceholder input[type="tel"]:read-only ~ label,
.inputPlaceholder input[type="email"]:read-only ~ label,
.inputPlaceholder input[type="text"]:read-only ~ label {
  top: -1px;
  font-size: 0.8125em;
  color: #707c80;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}
.inputPlaceholder input[type="number"]:disabled ~ label,
.inputPlaceholder input[type="password"]:disabled ~ label,
.inputPlaceholder input[type="tel"]:disabled ~ label,
.inputPlaceholder input[type="email"]:disabled ~ label,
.inputPlaceholder input[type="text"]:disabled ~ label {
  top: -1px;
  font-size: 0.8125em;
  color: #707c80;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}
.inputPlaceholder input[type="number"]:-webkit-autofill ~ label,
.inputPlaceholder input[type="password"]:-webkit-autofill ~ label,
.inputPlaceholder input[type="tel"]:-webkit-autofill ~ label,
.inputPlaceholder input[type="email"]:-webkit-autofill ~ label,
.inputPlaceholder input[type="text"]:-webkit-autofill ~ label {
  top: -1px;
  font-size: 0.8125em;
  color: #707c80;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}
.inputPlaceholder input[type="number"]:focus ~ label,
.inputPlaceholder input[type="password"]:focus ~ label,
.inputPlaceholder input[type="tel"]:focus ~ label,
.inputPlaceholder input[type="email"]:focus ~ label,
.inputPlaceholder input[type="text"]:focus ~ label {
  top: -1px;
  font-size: 0.8125em;
  color: #707c80;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.inputPlaceholder input[type="number"]:valid ~ label,
.inputPlaceholder input[type="password"]:valid ~ label,
.inputPlaceholder input[type="tel"]:valid ~ label,
.inputPlaceholder input[type="email"]:valid ~ label,
.inputPlaceholder input[type="text"]:valid ~ label {
  top: -1px;
  font-size: 0.8125em;
  color: #707c80;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.inputPlaceholder input[type="number"]:disabled,
.inputPlaceholder input[type="number"]:read-only,
.inputPlaceholder input[type="password"]:disabled,
.inputPlaceholder input[type="password"]:read-only,
.inputPlaceholder input[type="tel"]:disabled,
.inputPlaceholder input[type="tel"]:read-only,
.inputPlaceholder input[type="email"]:disabled,
.inputPlaceholder input[type="email"]:read-only,
.inputPlaceholder input[type="text"]:disabled,
.inputPlaceholder input[type="text"]:read-only {
  background-color: #f0f0f0 !important;
  border: 1px solid #ebeaf1 !important;
}
.inputPlaceholder input[type="number"]:focus ~ .bar:after,
.inputPlaceholder input[type="number"]:focus ~ .bar:before,
.inputPlaceholder input[type="password"]:focus ~ .bar:after,
.inputPlaceholder input[type="password"]:focus ~ .bar:before,
.inputPlaceholder input[type="tel"]:focus ~ .bar:after,
.inputPlaceholder input[type="tel"]:focus ~ .bar:before,
.inputPlaceholder input[type="email"]:focus ~ .bar:after,
.inputPlaceholder input[type="email"]:focus ~ .bar:before,
.inputPlaceholder input[type="text"]:focus ~ .bar:after,
.inputPlaceholder input[type="text"]:focus ~ .bar:before {
  width: 50%;
}

.inputPlaceholder .bar {
  position: relative;
  display: block;
  width: 100%;
}
.inputPlaceholder .bar:after,
.inputPlaceholder .bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #016db6;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}
.inputPlaceholder .bar:before {
  left: 50%;
}
.inputPlaceholder .bar:after {
  right: 50%;
}
.inputPlaceholder .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  width: 100% !important;
}

.inputPlaceholder label {
  color: #707c80;
  font-size: 1rem;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  margin: 3px 0;
  left: 5px;
  top: 10px;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.header-icon {
  color: #666;
  height: 3.5rem;
  display: block;
  line-height: 3.5rem;
  text-decoration: none;
  position: relative;
}
.header-icon:not(.btn) {
  min-width: 3.125rem;
  text-align: center;
  overflow: visible;
}
.header-icon:not(.btn) > .ni:first-child,
.header-icon:not(.btn) > [class*="fa-"]:first-child {
  color: black;
  vertical-align: middle;
}
.header-icon:not(.btn) > [class*="fa-"]:first-child {
  font-size: 21px;
}
.header-icon:not(.btn) > .ni:first-child {
  font-size: 21px;
}
.header-icon:not(.btn):hover > .ni,
.header-icon:not(.btn):hover > [class*="fa-"]:only-child {
  color: #404040;
}
.header-icon:not(.btn)[data-toggle="dropdown"][data-toggle="dropdown"]:after {
  content: " ";
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  background: #dae1e8;
  border-radius: 50%;
  top: 1.3125rem;
  z-index: -1;
  left: 0.9375rem;
  opacity: 0;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.header-icon:not(.btn)[data-toggle="dropdown"][aria-expanded="true"] {
  color: #404040;
  position: relative;
  z-index: 1001;
  font-weight: 500;
}
.header-icon:not(.btn)[data-toggle="dropdown"][aria-expanded="true"]:after {
  content: " ";
  width: 2.5rem;
  height: 2.5rem;
  top: 0.6rem;
  z-index: -1;
  left: 0.3125rem;
  opacity: 1;
  color: #ccc;
}
.header-icon:not(.btn)[data-toggle="dropdown"][aria-expanded="true"]
  > .ni:first-child,
.header-icon:not(.btn)[data-toggle="dropdown"][aria-expanded="true"]
  > [class*="fa-"]:first-child {
  color: #404040 !important;
  -webkit-text-fill-color: initial;
  background: 0 0;
}
.header-icon:not(.btn)[data-toggle="dropdown"] + .dropdown-menu {
  position: absolute;
  border: 0 solid #ccc;
  right: 2rem;
  top: 4.0625rem !important;
  padding: 0;
  margin: 0;
}

.header-icon:not(.btn) .profile-image {
  width: 2rem;
  height: auto;
}
.header-icon:hover {
  cursor: default;
  color: #404040;
}

.page-header {
  background-color: #fff;
  -webkit-box-shadow: 0 0 28px 0 rgba(61, 78, 124, 0.13);
  box-shadow: 0 0 28px 0 rgba(61, 78, 124, 0.13);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 4.125rem;
  position: relative;
  z-index: 1000;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.page-header .page-logo {
  display: none;
}
.page-header .badge-icon {
  left: 1.5625rem;
  top: 1.09375rem;
}
.page-header .badge-icon:only-child {
  position: relative;
  left: auto;
  right: auto;
  font-size: 14px;
  height: 26px;
  width: 26px;
  line-height: 21px;
  top: 20px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.badge.badge-icon {
  position: absolute;
  display: inline-block;
  background-color: rgb(24, 126, 247);
  color: #fff;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
  cursor: default;
  border: 1px solid transparent;
  font-size: 0.625rem;
  min-width: 1rem;
  max-width: 1.6875rem;
  border-radius: 1.25rem;
  font-weight: 500;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  top: 0.5rem;
  left: 1.79rem;
}
.nav-function-top .page-header .badge.badge-icon {
  -webkit-box-shadow: 0 0 0 1px #596fad;
  box-shadow: 0 0 0 1px #596fad;
}
.page-logo {
  height: 4.125rem;
  width: 16.875rem;
  -webkit-box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.13);
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-positive: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding: 0 2rem;
}
.page-logo img {
  width: auto;
  height: auto;
}
.page-logo .page-logo-link {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.page-logo-text {
  margin-left: 0.5rem;
  font-weight: 300;
  font-size: 1rem;
  color: #fff;
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: left;
}
.search {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.search .app-forms {
  padding: 0;
}
.search .app-forms.has-length {
  position: relative;
}
.search .app-forms:before {
  content: none;
}
#search-field {
  max-width: 21.875rem;
  height: 2.625rem;
  line-height: normal;
  border-radius: 4px;
  background: 0 0;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 0.9375rem;
  padding: 0.38rem;
}
.dropdown-icon-menu > .btn {
  z-index: 1;
}
.dropdown-icon-menu > ul {
  opacity: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  background: #fff;
  height: 2.25rem;
  padding: 2.75rem 4px 5px;
  width: 3.75rem;
  margin-left: 1px;
  margin-top: -2.5rem;
  left: -5px;
  overflow: hidden;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.24);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.24);
  border-radius: 0.25rem;
  -webkit-transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
}
.dropdown-icon-menu > ul > li {
  margin-bottom: 4px;
  position: relative;
}
.dropdown-icon-menu > ul > li:last-child {
  margin-bottom: 0;
}
.dropdown-icon-menu:hover > ul {
  display: block;
  opacity: 1;
  height: 7.75rem;
}
.dropdown-icon-menu:hover > ul:hover {
  overflow: visible;
}
.tab-notification {
  height: 363px;
}
.tab-notification .tab-pane {
  height: 100%;
}

.app-list {
  margin: 0 auto;
  display: block;
  width: 21.875rem !important;
  height: 22.5rem !important;
  font-size: 0;
  padding: 0.5rem 1rem;
  text-align: center;
}
.app-list > li {
  display: inline-block;
  text-align: center;
  padding: 0;
}
.app-list-item {
  height: 5.9375rem;
  width: 6.25rem;
  display: block;
  text-decoration: none;
  color: #666;
  margin: 10px 2px;
  border: 1px solid transparent !important;
  outline: 0;
  border-radius: 3px;
  padding-top: 8px;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.app-list-item .icon-stack {
  font-size: 3.125rem;
  margin-top: 4px;
}
.app-list-item:hover {
  border: 1px solid #e3e3e3;
  padding-top: 7px;
}
.app-list-item:hover > .icon-stack {
  font-size: 3.1875rem;
}
.app-list-item:active {
  border-color: #6a7eb5;
  padding-top: 8px;
}
.app-list-item:active > .icon-stack {
  font-size: 3.125rem;
}
.app-list-name {
  text-align: center;
  font-size: 0.8125rem;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.header-function-fixed:not(.nav-function-top) .page-header {
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
}
.header-function-fixed:not(.nav-function-top) .page-content {
  margin-top: 4.125rem;
}
.header-function-fixed:not(.nav-function-top):not(.nav-function-fixed)
  .page-logo {
  width: 16.875rem;
  position: fixed;
  top: 0;
  z-index: 950;
}
.header-function-fixed:not(.nav-function-top):not(.nav-function-fixed)
  .page-sidebar
  .primary-nav {
  margin-top: 4.125rem;
}
.header-function-fixed:not(.nav-function-top):not(.nav-function-fixed).desktop.nav-function-hidden
  .page-logo {
  position: absolute;
  -webkit-transition: none !important;
  transition: none !important;
}
@media (min-width: 992px) {
  .header-function-fixed:not(.nav-function-top) .page-header {
    margin-left: 16.875rem;
  }
  .header-function-fixed:not(.nav-function-top).nav-function-minify
    .page-sidebar
    .page-logo {
    width: 4.6875rem;
  }
  .header-function-fixed.nav-function-top .page-header {
    position: fixed !important;
    -webkit-box-shadow: 0 0 28px 2px rgba(61, 78, 124, 0.13);
    box-shadow: 0 0 28px 2px rgba(61, 78, 124, 0.13);
  }
}
.page-sidebar {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 16.875rem;
  max-width: 16.875rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1002;
  will-change: left, right;
}
.primary-nav {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.primary-nav .nav-menu:last-of-type {
  margin: 0;
}
.primary-nav .nav-menu:first-of-type {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nav-title {
  text-transform: uppercase;
  margin: 0;
  color: #6879a8;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  font-size: 0.7rem;
  letter-spacing: 1px;
  font-weight: 500;
}
.nav-menu {
  padding: 0;
  list-style: none;
  margin: 0;
}
.nav-menu a,
.nav-menu a > .ni,
.nav-menu a > [class*="fa-"] {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-menu b.collapse-sign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #7b8dbd;
}
.nav-menu b.collapse-sign > .ni,
.nav-menu b.collapse-sign > [class*="fa-"] {
  font-size: inherit;
}
.nav-menu ul {
  padding-left: 0;
  list-style: none;
  display: none;
}
.nav-menu li {
  position: relative;
}
.nav-menu li.open > a {
  color: #fff;
}
.nav-menu li.active > a {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.04);
  -webkit-box-shadow: inset 3px 0 0 #6a7eb5;
  box-shadow: inset 3px 0 0 #6a7eb5;
  font-weight: 400;
}
.nav-menu li.active > a:hover > .ni,
.nav-menu li.active > a:hover > [class*="fa-"] {
  color: #a6a8ac;
}
.nav-menu li.active > ul {
  display: block;
}
.nav-menu li.active:not(.open) > a:before {
  content: "\f413";
  font-family: nextgen-icons;
  position: absolute;
  top: calc(50% - 5px);
  right: 11px;
  font-size: 7px;
  height: 10px;
  width: auto;
  color: #24b3a4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.nav-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  outline: 0;
  padding: 0.8125rem 2rem;
  font-size: 0.875rem;
  color: #afb8d1;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}
.nav-menu li a .dl-ref {
  font-size: 0.625rem;
  text-align: center;
  min-width: 1.25rem;
  display: inline-block;
  border-radius: 4px;
  letter-spacing: 0.5px;
  margin-left: -2.1875rem;
  margin-right: 0.9375rem;
  font-weight: 500;
  overflow: hidden;
  padding: 0 4px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.nav-menu li a .dl-ref.label {
  margin-left: 0;
  margin-right: 0;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}
.nav-menu li a > .ni,
.nav-menu li a > [class*="fa-"] {
  margin-right: 0.25rem;
  font-size: 1.125rem;
  width: 1.75rem;
  color: #6f7fab;
}
.nav-menu li a > .nav-link-text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: normal;
}
.nav-menu li a.collapsed .nav-menu-btn-sub-collapse {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.nav-menu li a:hover {
  color: #fff;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-menu li a:hover .badge {
  color: #fff;
}
.nav-menu li a:hover > .ni,
.nav-menu li a:hover > [class*="fa-"] {
  color: #a6a8ac;
}
.nav-menu li a:hover > .badge {
  -webkit-box-shadow: 0 0 0 1px rgba(83, 99, 143, 0.8);
  box-shadow: 0 0 0 1px rgba(83, 99, 143, 0.8);
  border: 1px solid rgba(83, 99, 143, 0.8);
}
.nav-menu li a:focus {
  color: #fff;
}
.nav-menu li a:focus .badge {
  color: #fff;
}
.nav-menu li > ul {
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav-menu li > ul li a {
  color: #9faac7;
  padding: 0.8125rem 2rem 0.8125rem 4rem;
}
.nav-menu li > ul li a b.collapse-sign > .ni,
.nav-menu li > ul li a b.collapse-sign > [class*="fa-"] {
  font-size: inherit-2;
}
.nav-menu li > ul li a > .ni,
.nav-menu li > ul li a > [class*="fa-"] {
  margin-left: -2.1875rem;
  margin-right: 0.9375rem;
  color: #6f7fab;
  font-size: 0.875rem;
  width: 1.25rem;
  text-align: center;
}
.nav-menu li > ul li a > .badge {
  color: #fff;
  background-color: #ff4341;
  border: 1px solid #505050;
}
.nav-menu li > ul li a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-menu li > ul li a:hover > .nav-link-text > .ni,
.nav-menu li > ul li a:hover > .nav-link-text > [class*="fa-"] {
  color: #a6a8ac;
}
.nav-menu li > ul li.active > a {
  color: #fff;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}
.nav-menu li > ul li.active > a > .nav-link-text > .ni,
.nav-menu li > ul li.active > a > .nav-link-text > [class*="fa-"] {
  color: #fff;
}
.nav-menu li > ul li.active > a:hover > .nav-link-text > .ni,
.nav-menu li > ul li.active > a:hover > .nav-link-text > [class*="fa-"] {
  color: #a6a8ac;
}
.nav-menu li > ul li:last-child > ul {
  padding-bottom: 0;
}
.nav-menu li > ul li > ul li.active > a {
  color: #fff;
}
.nav-menu li > ul li > ul li a {
  color: #99a4c4;
  padding: 0.8125rem 2rem 0.8125rem 4.75rem;
}
.nav-menu li > ul li > ul li a .dl-ref {
  margin-left: 0;
  margin-right: 0.20833rem;
}
.nav-menu li > ul li > ul li a > .ni,
.nav-menu li > ul li > ul li a > [class*="fa-"] {
  margin-left: 0;
  margin-right: 0.20833rem;
}
.nav-menu li > ul li > ul li a:hover {
  color: #fff;
}
.nav-menu li > ul li > ul li a > .badge {
  color: #fff;
  background-color: #ff4341;
  border: 1px solid #505050;
}
.nav-menu li:last-child {
  margin-bottom: 0;
}
.nav-menu:last-child {
  margin-bottom: 0;
}
.nav-menu-clean {
  background: #fff;
}
.nav-menu-clean ul {
  background: 0 0 !important;
  padding-bottom: 0 !important;
}
.nav-menu-clean li a {
  background: 0 0 !important;
  color: #505050 !important;
}
.nav-menu-clean li a span {
  color: #505050 !important;
}
.nav-menu-clean li a:hover {
  background-color: #f4f4f4 !important;
}
.nav-menu-clean li a {
  border-bottom: 1px solid transparent;
}
.nav-menu-clean li > ul li > ul > li:not(:last-child) a {
  border-bottom: none;
}
.nav-menu-bordered {
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.nav-menu-bordered li a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.nav-menu-bordered li > ul li > ul > li:not(:last-child) a {
  border-bottom: none;
}
.nav-menu-compact li a {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.nav-menu-compact li li a {
  padding-left: 2rem !important;
}
.nav-menu-compact li li li a {
  padding-left: 2.5rem !important;
}
.nav-menu.nav-menu-reset .collapse-sign,
.nav-menu.nav-menu-reset li a:not(:hover) {
  color: rgba(255, 255, 255, 0.7) !important;
}
@media (min-width: 1399px) {
  .page-sidebar .primary-nav .nav-menu > li > a {
    font-size: 0.875rem;
  }
}
.nav-filter {
  margin: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  position: relative;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transition: all 0.4s cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 0.4s cubic-bezier(0.34, 1.25, 0.3, 1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.nav-filter input[type="text"] {
  padding: 8px 40px 8px 14px;
  width: 14.625rem;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.nav-filter input[type="text"]:not(:focus) {
  border-color: rgba(0, 0, 0, 0.1);
}
.nav-filter input[type="text"]:focus {
  border-color: #5c6e9f;
}
.list-filter-active .info-card {
  height: calc(9.53rem - 9.53rem);
  -webkit-transition: all 0.4s cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 0.4s cubic-bezier(0.34, 1.25, 0.3, 1);
}
.list-filter-active .nav-filter {
  opacity: 1;
  visibility: visible;
  height: 60px;
  -webkit-box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.13);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.list-filter-active .nav-title {
  display: none;
}
.list-filter-active .nav-menu {
  margin: 0;
}
.list-filter-active .nav-menu li > ul {
  padding: 0;
}
.list-filter-active .js-filter-hide {
  display: none;
}
.list-filter-active .js-filter-show {
  display: block;
}
.page-sidebar:not(.list-filter-active) .filter-message {
  display: none;
}

.dropdown-header.bg-trans-gradient {
  padding: 1.25rem 1.5rem;
}
.dropdown-menu-animated {
  -webkit-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
  -webkit-transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  opacity: 0;
  visibility: hidden;
  display: block;
}
.dropdown-menu {
  -webkit-box-shadow: 0 0 15px 1px rgba(80, 87, 105, 0.2);
  box-shadow: 0 0 15px 1px rgba(80, 87, 105, 0.2);
  -webkit-user-select: text;
}
.dropdown-menu .dropdown-item {
  font-weight: 400;
  cursor: pointer;
}
.dropdown-menu.dropdown-sm {
  width: 8rem;
  height: auto;
}
.dropdown-menu.dropdown-md {
  width: 14rem;
  height: auto;
}
.dropdown-menu.dropdown-lg {
  width: 17.5rem;
  height: auto;
}
.dropdown-menu.dropdown-xl {
  width: 21.875rem;
  height: auto;
}
.dropdown-menu .dropdown-item:first-child,
.dropdown-menu .dropdown-item:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.dropup .dropdown-toggle:after {
  content: "\f106" !important;
}
.dropright .dropdown-toggle:after {
  content: "\f105" !important;
}
.dropleft .dropdown-toggle:before {
  content: "\f104" !important;
}
.nav-item .dropdown-toggle:after {
  font-size: 0.9em;
}
.dropdown-toggle.no-arrow:after,
.dropdown-toggle.no-arrow:before {
  display: none !important;
}
.dropdown-menu .dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 0;
  position: absolute;
  top: -1px;
  left: 100%;
  background: #fff;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  opacity: 0;
  visibility: hidden;
  display: block;
}
.dropdown-menu .dropdown-menu .dropdown-item {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.dropdown-menu .dropdown-multilevel {
  position: relative;
}
.dropdown-menu .dropdown-multilevel.dropdown-multilevel-left > .dropdown-menu {
  right: 100%;
  left: auto;
}
.dropdown-menu .dropdown-multilevel > .dropdown-item:first-child:after {
  content: "\f2fb";
  font-family: nextgen-icons;
  font-size: inherit;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 1.5rem;
}
.dropdown-menu .dropdown-multilevel:hover > .dropdown-item:not(.disabled) {
  background: #f8f9fa;
  color: #4e639e;
}
.dropdown-menu
  .dropdown-multilevel:hover
  > .dropdown-item:not(.disabled)
  + .dropdown-menu {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 29px -50px;
  transform-origin: 29px -50px;
  opacity: 1;
  visibility: visible;
}
.icon-stack {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  text-align: center;
}
.icon-stack-1x,
.icon-stack-2x,
.icon-stack-3x {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: inherit !important;
}
.icon-stack-1x {
  font-size: 0.5em;
}
.icon-stack-2x {
  font-size: 0.7em;
}
.icon-stack-3x {
  font-size: 1em;
}
.icon-stack-xl {
  font-size: 3.125rem;
}
.icon-stack-lg {
  font-size: 2.5rem;
}
.icon-stack-md {
  font-size: 2.125rem;
}
.icon-stack-sm {
  font-size: 1.875rem;
}
.filter-message {
  display: block;
  text-align: center;
  padding: 2px;
  font-size: 0.6875rem;
  text-transform: capitalize;
  font-style: italic;
  width: calc(100% - 60px);
  max-width: 180px;
  border-radius: 4px;
  margin: 1rem auto;
}
.filter-message:empty {
  display: none;
}
.js-list-filter:not(.primary-nav) .js-filter-hide {
  display: none !important;
}
.js-list-filter.nav-menu:not(.primary-nav) .js-filter-show {
  display: block !important;
}

.color-white {
  color: #fff;
}

.show > .dropdown-menu-animated {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transform-origin: 29px -50px;
  transform-origin: 29px -50px;
  opacity: 1;
  visibility: visible;
}

/*20200308*/

.timeline-title {
  margin: 0 auto;
  max-width: 472px;
}

/**
* main style
*/
.timeline-item {
  background-color: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  min-height: 200px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eeeeee),
    color-stop(18%, #dddddd),
    color-stop(33%, #eeeeee)
  );
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */
.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.detalleCasas:hover {
  background-color: white !important;
}

.detalleCasas {
  background-color: white !important;
}

/* 
Formato de fondo y valores
Idefificacion oficial
Comprobate de domicilio
Estdo de cuenta con Clabe 
 */

div.disabled,
div[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

/* hide */
.ocultarDiv {
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  max-height: 0px;
  /* fade out, then shrink */
  transition: opacity 0.25s, font-size 0.5s 0.25s, margin 0.5s 0.25s,
    padding 0.5s 0.25s;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
}

/* reveal */
.mostrarDiv {
  /* unshrink, then fade in */
  transition: font-size 0.25s, margin 0.25s, padding 0.25s, opacity 0.5s 0.25s;
  margin-bottom: 25px;
}

.invalid-cargo {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.nHeader {
  background-clip: padding-box;
  background-color: #fff;
  border-bottom: solid 1px #dddfe2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 8px 12px 6px;
  position: relative;
  z-index: 100;
}

.rfloat {
  float: right;
  right: 0;
  padding-right: 10px;
}

.nHeader h3 {
  color: #333;
  font-size: 12px;
  font-weight: 600;
}

.nHeader span {
  color: #385898;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
}

.nFooter span {
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid #dddfe2;
  display: block;
  font-weight: 600;
  padding: 8px 12px;
  position: relative;
  text-align: center;
  z-index: 100;
  font-size: 12px;
  font-weight: 600;
}

.vCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nTitle {
  margin-top: 8px;
}

.pRelative {
  position: relative;
}

.notificacionElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1px;
  min-height: 50px;
  word-wrap: break-word;
  background-color: #fff;
  border-bottom: 1px solid #dddfe2;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.34;
  padding: 10px;
}

.tituloNotificacion {
  display: block;
}

.comunicado-wrapper {
  min-height: 300px;
}

.card-waves .card-body {
  background-image: url("./img/wave.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.visitanteDiv {
  padding: 15px;
}

.ultimasVisitasDiv {
  padding: 15px;
}

.list-div {
  background: #f5f5f5;
  padding: 15px;
  border: 1px dashed #f5f5f5;
  border-radius: 8px;
  margin-bottom: 15px;
  background-origin: content-box;
}

.menu-ellipsis {
  font-size: 30px;
  font-weight: bolder;
  float: right;
  vertical-align: top;
  cursor: pointer;
}

.menu-ellipsis:focus .dropdown-custom {
  display: block !important;
}

.dropdown-acceso {
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
}

.dropdown-acceso button:hover {
  background-color: #ddd;
}

.dropdown-acceso button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  border: none;
}

.dot-ok {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dot-warning {
  height: 10px;
  width: 10px;
  background-color: #8d15fc;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dot-alert {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.px-4-5 {
  padding-left: 1.6rem !important;
  padding-right: 1.6rem !important;
}

.widget-indicators {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}
.indicator-item {
  padding: 12px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  height: 98px;
}
.indicator-item-icon {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.indicator-item-icon .icon {
  font-size: 3.07692rem;
  color: #2cc185;
}
.indicator-item-value {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.indicator-value-counter {
  font-size: 1.84615rem;
}
.indicator-value-title {
  font-weight: 300;
  margin-top: 10px;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.select-area-comun {
  margin-right: 10px;
}

.listInactive {
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
  display: block;
}

.subscripcionesList {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.subscripcionesList li {
  padding: 5px;
}

.crearAsociacionForm {
  width: 60%;
  margin: auto;
}

.invalid-feedback-card {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #dc3545;
}

.suscripcion {
  margin: 0px 20px;
}

.barraBusqueda {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.display-on-sm {
  display: none;
}

.menu-sm {
  padding: 15px;
}

.btn-sm {
  margin-right: 15px;
}

@media (max-width: 400px) {
  .d-sm-none {
    display: none;
  }
  .barraBusqueda {
    display: initial;
    align-items: center;
    width: 100%;
  }
  .display-on-sm {
    display: block;
  }

  .padding-on-sm {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .menu-sm {
    padding: 0px;
  }
  .width-sm {
    width: 100%;
  }

  .btn-sm {
    margin-right: 0px;
  }
}
