.StripeElement {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 20px;
  width: 100%;
}
.inputStriper {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.StripeElement .result-message {
  line-height: 22px;
  font-size: 16px;
}
.StripeElement .result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.StripeElement .hidden {
  display: none;
}
.StripeElement #card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
.StripeElement #card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.StripeElement #payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.submitButton {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.submitButton:hover {
  filter: contrast(115%);
}
.submitButton:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.StripeElement .spinner:after {
  border-radius: 50%;
}
.StripeElement .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.StripeElement .spinner:after {
  position: absolute;
  content: "";
}
.StripeElement .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.StripeElement .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.active-step .bs-stepper-label {
  color: blue !important;
}

.bs-stepper .step-trigger {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}
.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.bs-stepper .step-trigger.disabled,
.bs-stepper .step-trigger:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.bs-stepper .step-trigger:hover {
  text-decoration: none;
}

.bs-stepper .step-trigger:focus {
  outline: none;
}

@media (max-width: 520px) {
  .bs-stepper .step-trigger {
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px;
  }
}
.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}
.bs-stepper-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 520px) {
  .bs-stepper-header {
    margin: 0 -10px;
    text-align: center;
  }
}
.bs-stepper .line,
.bs-stepper-line {
  -ms-flex: 1 0 32px;
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.12);
}
@media (max-width: 400px) {
  .bs-stepper .line,
  .bs-stepper-line {
    -ms-flex-preferred-size: 20px;
    flex-basis: 20px;
  }
}
.bs-stepper-circle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  margin: 0.25rem;
  line-height: 1em;
  color: #fff;
  background-color: #6c757d;
  border-radius: 1em;
}
.active .bs-stepper-circle {
  background-color: #007bff;
}
.bs-stepper-content {
  padding: 0 20px 20px;
  margin: auto;
}
@media (max-width: 520px) {
  .bs-stepper-content {
    padding: 0;
  }
}

.bs-stepper.vertical {
  display: -ms-flexbox;
  display: flex;
}
.bs-stepper.vertical .bs-stepper-header {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0;
}
.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
  display: block;
}
.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}
.bs-stepper .content:not(.fade),
.bs-stepper-pane:not(.fade) {
  display: none;
}
.bs-stepper .content.fade,
.bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.bs-stepper .content.fade.active,
.bs-stepper-pane.fade.active {
  visibility: visible;
  opacity: 1;
}
.bs-stepper .content.active:not(.fade),
.bs-stepper-pane.active:not(.fade) {
  display: block;
  visibility: visible;
}
.bs-stepper .content.dstepper-block,
.bs-stepper-pane.dstepper-block {
  display: block;
}
.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
}
.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
  visibility: hidden;
}
/*# sourceMappingURL=bs-stepper.min.css.map */

@media (max-width: 600px) {
  .asociacion-registro {
    margin-left: 0px;
  }
}

.tipoPlan {
  margin-top: 30px;
}

.espacioForm {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Text {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
  color: rgba(26, 26, 26, 0.7);
  font-size: 13px;
  display: flex;
}

.resumenCuenta {
  box-shadow: 5px 0px 5px -1px rgb(0 0 0 / 10%);
}

@media (max-width: 768px) {
  .resumenCuenta {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
  }
}

.spanCosto {
  display: block;
  font-size: 10px;
  font-weight: 400;
}

.pCosto {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}

.pTexto {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.pointerFila {
  cursor: pointer;
}

.cuenta label {
  font-weight: bold;
}
